import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { t } from 'i18next';
import EmphasisTag from 'components/base-components/EmphasisTag';

const OverviewContent = ({ className, label, value, noMargin, children, preferred }) => (
  <div
    className={classNames(
      'organisation-overview__content',
      {
        'organisation-overview__content--margin-none': noMargin,
      },
      className,
    )}
  >
    <span>
      <span className="organisation-overview__content-label">{label}</span>
      {preferred && <EmphasisTag radius="oval" text="Preferred" type="normal" />}
    </span>
    <span
      className={classNames('organisation-overview__content-value', {
        'organisation-overview__content-value--disabled': !value,
      })}
    >
      {value || t('common.not_applicable')}
    </span>
    {children}
  </div>
);

OverviewContent.defaultProps = {
  value: '',
  noMargin: false,
  className: '',
  children: null,
  preferred: false,
};

OverviewContent.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.node,
  noMargin: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  preferred: PropTypes.bool,
};

export default OverviewContent;
