import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { Table, TableHead, TableHeader, TableRow } from 'components/base-components/Table';
import Card from 'components/base-components/Card';
import { useGetOrganizationQuery } from 'api/organizations';
import { PRODUCER } from 'utils/organisation-industries';
import InvoiceLineItems from './line-items.component';
import '../../invoice.styles.scss';

export const InvoiceTableHeader = ({ organization }) => {
  return (
    <TableHead>
      <TableRow>
        <TableHeader align="center">{t('invoice.sl_no')}</TableHeader>
        <TableHeader>{t('invoice.service')}</TableHeader>
        <TableHeader align="center">{t('invoice.qty')}</TableHeader>
        {organization?.industry === PRODUCER && (
          <TableHeader align="center">{t('invoice.unit')}</TableHeader>
        )}
        <TableHeader align="right">{t('invoice.price')}</TableHeader>
        <TableHeader align="right">{t('invoice.total_amount')}</TableHeader>
      </TableRow>
    </TableHead>
  );
};

const InvoiceItemsTable = ({ invoice }) => {
  const { data: organization } = useGetOrganizationQuery(invoice?.organizationId);
  return (
    <Card>
      <span className="invoice__line-item--title">{t('invoice.line_item')}</span>
      <Table className="invoice-table">
        <InvoiceTableHeader organization={organization} />
        <InvoiceLineItems invoice={invoice} organization={organization} />
      </Table>
    </Card>
  );
};

InvoiceTableHeader.propTypes = {
  organization: PropTypes.shape().isRequired,
};

InvoiceItemsTable.propTypes = {
  invoice: PropTypes.shape().isRequired,
};
export default InvoiceItemsTable;
