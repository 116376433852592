import InvoiceWrapper from 'components/invoices/list/admin';
import RecordPaymentComponent from 'components/invoices/record-payment/index.component';
import CreateMessage from 'components/message-management/create-message.component';
import MessageManagementComponent from 'components/message-management/message-management.component';
import OrganisationDetailsWrapper from 'components/organisations/details/organisation-details-wrapper';
import InvoiceShowPage from 'components/invoices/show';
import OrganisationView from 'components/organisations/view/organisation-view.component';
import CreateUpdateTask from 'components/task-management/create-update-task.component';
import TaskDetailsTab from 'components/task-management/task-details/task-details-tab.component';
import UserDetails from 'components/users/details/index.component';
import InviteUserComponent from 'components/users/invite-user-form.component';
import UserEditComponent from 'components/users/user-edit.component';
import Home from 'pages/Home';
import Login from 'pages/Login';
import TaskManagement from 'pages/TaskManagement';
import Users from 'pages/Users';
import CreateOrganisation from 'pages/create-organisation';
import EditOrganisation from 'pages/edit-organisation';
import Organisation from 'pages/organisations';
import ResetPassword from 'pages/reset-password';
import ResetPasswordRequest from 'pages/reset-password-request';
import SetPassword from 'pages/set-password';
import VerifyPinCode from 'pages/verify-pin-code';
import Enforce2FA from 'pages/enforce-2fa';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import NotFoundPage from 'components/not-found';
import ProtectedRoute from 'utils/protected-route';
import PublicRoute from 'utils/public-route';
import ChangeOrganisationAddressForm from 'components/organisations/form/contact-and-address/change-address/index.component';
import { ADMIN, USER, CONTROL_BODY_USER } from 'utils/users-role';
import TVDPlaceOrder from 'components/organisations/local-unit/form/place-order.component';
import InvoiceListComponent from 'components/invoices/list/organisation/index.component';
import TvdCreateComponent from 'components/organisations/local-unit/form/create/index.component';
import TVDEditComponent from 'components/organisations/local-unit/form/edit/index.component';
import OrganisationChecklist from 'components/control-body/bio-cuisine-assessment/checklist/checklist-form.component';
import ChangeModeOfCommunication from 'components/organisations/form/contact-and-address/change-communication-mode';
import ChangeOranisationContactForm from 'components/organisations/form/contact-and-address/change-contact/index.component';
import RecognitionDetailsWrapper from 'components/recognition/details/wrapper.component';
import DataCenter from 'components/data-center/index.component';
import LegalFormEdit from 'components/organisations/form/producer/legal-form/edit.component';
import EditManualInvoice from '../invoices/Form/edit/index.component';
import CreateManualInvoice from '../invoices/Form/create/index.component';

export default function RoutesComponent() {
  return (
    <Routes>
      <Route element={<PublicRoute />}>
        <Route path="/login" element={<Login />} />
        <Route path="/reset-password/request" element={<ResetPasswordRequest />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/set-password" element={<SetPassword />} />
        <Route path="/verify-pin" element={<VerifyPinCode />} />
        <Route path="/enforce-2fa" element={<Enforce2FA />} />
      </Route>

      <Route element={<ProtectedRoute allowedRoles={[ADMIN, CONTROL_BODY_USER, USER]} />}>
        <Route path="/" element={<Home />} />
        <Route path="/restaurants" element={<Organisation industry="gastronomy" />} />
        <Route path="/producers" element={<Organisation industry="producer" />} />
        <Route path="/control-body" element={<Organisation industry="control_body" />} />
        <Route path="/organisations" element={<Organisation industry="control_body" />} />
        <Route path="/organisations/:id" element={<OrganisationDetailsWrapper />} />
        <Route
          path="/organisations/:id/change-address"
          element={<ChangeOrganisationAddressForm />}
        />
        <Route
          path="/organisations/:id/change-contact"
          element={<ChangeOranisationContactForm />}
        />
        <Route
          path="/organisations/:id/preferred-language/edit"
          element={<ChangeModeOfCommunication />}
        />
        <Route path="/organisations/new" element={<CreateOrganisation />} />
        <Route path="/producers/new" element={<CreateOrganisation department="producer" />} />
        <Route path="/restaurants/new" element={<CreateOrganisation department="gastronomy" />} />
        <Route
          path="/control-body/new"
          element={<CreateOrganisation department="control_body" />}
        />
        <Route path="/organisations/:id/view" element={<OrganisationView />} />
        <Route path="/organisations/:id/edit" element={<EditOrganisation />} />
        <Route path="/organisations/:id/control-body-surveys/:surveyId/perform-control" element={<OrganisationChecklist />} />
        <Route path="/organisations/:id/control-body-surveys/:surveyId/view" element={<OrganisationChecklist />} />
        <Route path="/users/:userId/edit" element={<UserEditComponent />} />
        <Route
          path="/organisations/:organisationId/users/:userId/edit"
          element={<UserEditComponent />}
        />
        <Route path="organisations/:id/invite" element={<InviteUserComponent />} />
        <Route path="/tasks" element={<TaskManagement />} />
        <Route path="/tasks/:id" element={<TaskDetailsTab />} />
        <Route path="/tasks/create" element={<CreateUpdateTask />} />
        <Route path="/tasks/edit/:id" element={<CreateUpdateTask />} />
        <Route path="/organisations/:id/local-units/create" element={<TvdCreateComponent />} />
        <Route
          path="/organisations/:id/local-units/edit/:localUnitId"
          element={<TVDEditComponent />}
        />
        <Route path="/organisations/:id/local-units/place-order" element={<TVDPlaceOrder />} />
        <Route path="/organisations/:id/invoices/:invoiceId" element={<InvoiceShowPage />} />
        <Route path="/organisations/:id/invoices/create" element={<CreateManualInvoice />} />
        <Route path="/organisations/:id/invoices/:invoiceId/edit" element={<EditManualInvoice />} />
      </Route>
      <Route element={<ProtectedRoute allowedRoles={[ADMIN, CONTROL_BODY_USER]} />}>
        <Route path="/users" element={<Users />} />
        <Route path="/users/invite" element={<InviteUserComponent />} />
      </Route>
      <Route element={<ProtectedRoute allowedRoles={[ADMIN, USER]} />}>
        <Route path="/messages" element={<MessageManagementComponent />} />
        <Route path="/messages/create" element={<CreateMessage />} />
        <Route path="/messages/edit/:id" element={<CreateMessage />} />
        <Route path="/users/:id" element={<UserDetails />} />
      </Route>
      <Route element={<ProtectedRoute allowedRoles={[ADMIN]} />}>
        <Route path="/invoices" element={<InvoiceWrapper />} />
        <Route path="/invoices/list" element={<InvoiceListComponent />} />
        <Route path="/invoices/create" element={<CreateManualInvoice />} />
        <Route path="/invoices/:invoiceId/edit" element={<EditManualInvoice />} />
        <Route path="/invoices/:invoiceId/record-payment" element={<RecordPaymentComponent />} />
        <Route path="/data-center" element={<DataCenter />} />
        <Route path="/recognition/:date" element={<RecognitionDetailsWrapper />} />
        <Route path="/organisations/:id/legal-form/edit" element={<LegalFormEdit />} />
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
