import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TableLoaderPlaceholder from 'components/shared-components/placeholders/table-loader-placeholder';
import EmptyContentPlaceholder from 'components/shared-components/placeholders/empty-content-placeholder-component';
import {
  Table,
  TableHeader,
  TableHead,
  TableRow,
  TableData,
  TableBody,
} from 'components/base-components/Table';
import '../organisations.styles.scss';
import { ORGANISATIONS_STATUS } from 'utils/statuses';
import { GASTRONOMY, PRODUCER } from 'utils/organisation-industries';
import { ABORTED } from 'utils/signed-contract-statuses';
import { dateMonthYear } from 'utils/date-time-formatter';
import { getCertificationStatus } from 'utils/get-certification-status';
import ORGANISATION_TYPE from 'utils/organisation-type';
import { useGetCurrentUserQuery } from '../../../api/users';
import isBioSuisseAdmin from '../../../utils/is-bio-suisse-admin';
import OrganisationColumn from './organisation-column.component';
import OrganisationRating from './organisation-rating.component';
import OrganisationMembers from './organisation-members.component';
import OrganisationHealth from './organisation-health.component';
import ParentOrganisation from './parent-organisation.component';
import SubOrganisation from './sub-organisation.component';
import OrganisationAction from './organisation-action.component';
import ShowStatus from './show-status.component';

const getColumns = (industry, tab, isAdmin) => {
  const controlBodyAttributes = ['uid', 'organisation', 'members', 'actions'];
  const producerAttributes = ['bio_id', 'organisation', 'contract_status', 'actions'];
  const gastronomyAttributes = [
    'bio_id',
    'organisation',
    'first_legitimization',
    'status',
    'members',
    'organisation_health',
    'main_organisation',
    'sub_organisation',
    'actions',
  ];

  let columnsToPresent = controlBodyAttributes;

  if (industry === GASTRONOMY) {
    columnsToPresent = gastronomyAttributes;
  } else if (industry === PRODUCER) {
    columnsToPresent = producerAttributes;
  }

  switch (industry) {
    case GASTRONOMY:
      if (!isAdmin) {
        columnsToPresent.splice(4, 1);
      }
      break;
    case PRODUCER:
      if (tab === ORGANISATIONS_STATUS.active) {
        columnsToPresent.splice(2, 1, 'certification_status', 'bio_suisse_status');
      } else if (tab === ORGANISATIONS_STATUS.inactive) {
        columnsToPresent.splice(2, 1, 'termination_date');
      } else if (tab === ABORTED) {
        columnsToPresent.splice(2, 1, 'contract_status', 'cancellation_date');
      }
      break;
  }

  return columnsToPresent;
};

const tableProps = {
  actions: { align: 'center' },
};

const OrganizationsTableRow = ({ tab, organization, isAdmin, parentOrganisationId, industry }) => {
  const { t } = useTranslation();
  const isGastronomy = organization?.industry === GASTRONOMY;
  const showHealth =
    isAdmin || !isGastronomy || organization.type === ORGANISATION_TYPE.parent_organisation;

  const columns = {
    actions: <OrganisationAction organization={organization} />,
    bio_id: organization.bioId || t('common.not_applicable'),
    contract_status: <ShowStatus status={organization?.contractStatus} type="success" />,
    cancellation_date: t('common.not_applicable'),
    organisation: <OrganisationColumn organization={organization} />,
    organisation_status: <ShowStatus status={organization?.status} type="success" />,
    certification_status: (
      <ShowStatus
        status={getCertificationStatus(organization?.certificationStatus)?.toUpperCase()}
        type="success"
      />
    ),
    bio_suisse_status: (
      <ShowStatus status={organization?.bioSuisseStatus?.toUpperCase()} type="success" />
    ),
    members: <OrganisationMembers organization={organization} />,
    organisation_health: <OrganisationHealth organization={organization} showHealth={showHealth} />,
    main_organisation: (
      <ParentOrganisation organization={organization} parentOrganisationId={parentOrganisationId} />
    ),
    status: <OrganisationRating organization={organization} />,
    sub_organisation: <SubOrganisation organization={organization} />,
    termination_date: t('common.not_applicable'),
    uid: organization.uid || t('common.not_applicable'),
    first_legitimization: organization.firstLegitimizedAt
      ? dateMonthYear(organization.firstLegitimizedAt)
      : t('common.not_applicable'),
  };

  return (
    <TableRow className="organisation__table-row">
      {getColumns(industry, tab, isAdmin)?.map((column, idx) => (
        <TableData {...(tableProps[column] || {})} key={idx}>
          {columns[column]}
        </TableData>
      ))}
    </TableRow>
  );
};

OrganizationsTableRow.defaultProps = {
  isAdmin: false,
  parentOrganisationId: null,
};

OrganizationsTableRow.propTypes = {
  organization: PropTypes.shape().isRequired,
  isAdmin: PropTypes.bool,
  parentOrganisationId: PropTypes.node,
  industry: PropTypes.string.isRequired,
  tab: PropTypes.string.isRequired,
};

const OrganizationsTable = ({ tab, organizations, isLoading, parentOrganisationId, industry }) => {
  const { data: currentUser } = useGetCurrentUserQuery();
  const { t } = useTranslation();
  const isAdmin = isBioSuisseAdmin(currentUser);

  const OrganisationListTableHeader = ({ industry }) => (
    <TableHead>
      <TableRow>
        {getColumns(industry, tab, isAdmin)?.map((headerName, idx) => (
          <TableHeader key={idx} {...(tableProps[headerName] || {})}>
            {t(`organisation.attributes.${headerName}`)}
          </TableHeader>
        ))}
      </TableRow>
    </TableHead>
  );

  return (
    <>
      {!isLoading && organizations.length === 0 ? (
        <EmptyContentPlaceholder
          iconName="organization"
          text={t('organisation.user_view.table_head.no_organisations')}
        />
      ) : (
        <Table className="organisation_table">
          <OrganisationListTableHeader industry={industry} />
          <TableBody>
            {isLoading ? (
              <TableLoaderPlaceholder
                numberOfRows={10}
                numberOfColumns={industry === PRODUCER ? 4 : 8}
              />
            ) : (
              organizations.map(organization => (
                <OrganizationsTableRow
                  key={`organization-list-${organization.id}`}
                  isAdmin={isAdmin}
                  organization={organization}
                  parentOrganisationId={parentOrganisationId}
                  industry={industry}
                  tab={tab}
                />
              ))
            )}
          </TableBody>
        </Table>
      )}
    </>
  );
};

OrganizationsTable.defaultProps = {
  isLoading: false,
  parentOrganisationId: null,
};

OrganizationsTable.propTypes = {
  organizations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isLoading: PropTypes.bool,
  parentOrganisationId: PropTypes.node,
  industry: PropTypes.string.isRequired,
  tab: PropTypes.string.isRequired,
};

export default OrganizationsTable;
