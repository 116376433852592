import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'components/base-components/Spinner';
import { useTranslation } from 'react-i18next';
import { useGetOrganizationHealthQuery } from 'api/organizations';
import HealthStatus from '../health-status';

const OrganisationHealth = ({ organization, showHealth }) => {
  const { t } = useTranslation();
  const { data: health = {}, isFetching } = useGetOrganizationHealthQuery(organization.id, {
    skip: !showHealth,
  });

  if (!showHealth) return t('shared.no_availability');

  return isFetching ? (
    <Spinner bgColor="none" color="success" size="tiny" />
  ) : (
    <HealthStatus
      status={health.type}
      task_count={health.pendingTasksCount}
      messages_count={health.unconfirmedMessagesCount}
    />
  );
};

OrganisationHealth.propTypes = {
  organization: PropTypes.shape().isRequired,
  showHealth: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool.isRequired,
};

export default OrganisationHealth;
